export const PRICING_PLANS_APP_DEF_ID = '1522827f-c56c-a5c9-2ac9-00f9e6ae12d3';
export const NO_BREAK_SPACE = '\u00A0';
export const BOOKINGS_APP_DEF_ID = '13d21c63-b5ec-5912-8397-c3a5ddb27a97';

export const EXPERIMENTS = {
  IMPROVED_APP_SETTINGS_VIEWER: 'specs.membership.ImprovedAppSettings',
  IMPROVED_APP_SETTINGS_EDITOR: 'specs.membership.ImprovedAppSettingsEditor',
  SETTINGS_PAGINATED_PLANS: 'specs.membership.SettingsPaginatedPlans',
};

export const HOOKS = {
  PP_SETTINGS_CONTENT: 'settings-tab-content',
  PP_SETTINGS_PLANS_CREATE_NEW: 'plans-tab-create-new',
  PP_SETTINGS_DISPLAY_TAB: 'display-tab',
  PP_SETTINGS_DISPLAY_TAB_SHOW_TITLE: 'show-title',
  PP_SETTINGS_DISPLAY_TAB_SHOW_SUBTITLE: 'show-subtitle',
  PP_SETTINGS_DISPLAY_TAB_SHOW_HIGHLIGHTED_PLAN: 'show-highlighted-plan',
  PP_SETTINGS_DISPLAY_TAB_SHOW_RIBBON: 'show-ribbon',
  PP_SETTINGS_DESIGN_TAB: 'design-tab',
  PP_SETTINGS_DESIGN_BAB: 'design-backgrounds-and-borders',
  PP_SETTINGS_DESIGN_BAB_TAB: 'design-backgrounds-and-borders-tab',
  PP_SETTINGS_DESIGN_BAB_APP_BACKGROUND_PICKER: 'app-background-picker',
  PP_SETTINGS_DESIGN_BAB_R_TAB: 'regular-card-tab',
  PP_SETTINGS_DESIGN_BAB_H_TAB: 'highlighted-card-tab',
  PP_SETTINGS_DESIGN_BAB_R_TITLE_BACKGROUND_PICKER: 'title-background-picker',
  PP_SETTINGS_DESIGN_BAB_R_BENEFITS_BACKGROUND_PICKER: 'benefits-background-picker',
  PP_SETTINGS_DESIGN_BAB_R_PLAN_BORDER_WIDTH_SLIDER: 'card-border-width-slider',
  PP_SETTINGS_DESIGN_BAB_R_CARD_BORDER_PICKER: 'card-border-picker',
  PP_SETTINGS_DESIGN_BAB_R_SHADOW_TOGGLE: 'card-regular-shadow-toggle',
  PP_SETTINGS_DESIGN_BAB_R_SHADOW_SETTINGS: 'card-regular-shadow-settings',
  PP_SETTINGS_DESIGN_BAB_H_SHADOW_TOGGLE: 'card-highlighted-shadow-toggle',
  PP_SETTINGS_DESIGN_BAB_H_SHADOW_SETTINGS: 'card-highlighted-shadow-settings',
  PP_SETTINGS_DESIGN_BAB_H_BENEFITS_BACKGROUND_PICKER: 'highlighted-plan-benefits-background-picker',
  PP_SETTINGS_DESIGN_BAB_H_TITLE_BACKGROUND_PICKER: 'highlighted-plan-title-background-picker',
  PP_SETTINGS_DESIGN_BAB_RESET_BUTTON: 'reset-background-and-borders-styles',
  PP_SETTINGS_DESIGN_TEXT: 'design-texts',
  PP_SETTINGS_DESIGN_TEXT_TAB: 'design-texts-tab',
  PP_SETTINGS_DESIGN_TEXT_HIGHLIGHTED: 'design-texts-highlighted',
  PP_SETTINGS_DESIGN_TEXT_HIGHLIGHTED_TAB: 'design-texts-highlighted-tab',
  PP_SETTINGS_DESIGN_TEXT_PAGE_HEADER_PICKER: 'page-header-picker',
  PP_SETTINGS_DESIGN_TEXT_PLAN_TITLE_PICKER: 'plan-title-picker',
  PP_SETTINGS_DESIGN_TEXT_PLAN_PRICE_PICKER: 'plan-price-picker',
  PP_SETTINGS_DESIGN_TEXT_PLAN_TAGLINE_PICKER: 'plan-tagline-picker',
  PP_SETTINGS_DESIGN_TEXT_PLAN_BENEFITS_PICKER: 'plan-benefits-picker',
  PP_SETTINGS_DESIGN_TEXT_RESET_BUTTON: 'reset-text-styles',
  PP_SETTINGS_DESIGN_BUTTON: 'design-button',
  PP_SETTINGS_DESIGN_BUTTON_R_STYLE_EMPTY_CORNERED: 'button-style-empty-cornered',
  PP_SETTINGS_DESIGN_BUTTON_R_STYLE_EMPTY_ROUNDED: 'button-style-empty-rounded',
  PP_SETTINGS_DESIGN_BUTTON_R_STYLE_FULL_CORNERED: 'button-style-full-cornered',
  PP_SETTINGS_DESIGN_BUTTON_R_STYLE_FULL_ROUNDED: 'button-style-full-rounded',
  PP_SETTINGS_DESIGN_BUTTON_R_CORNER_RADIUS: 'button-corner-radius',
  PP_SETTINGS_DESIGN_BUTTON_R_BORDER_WIDTH: 'button-border-width',
  PP_SETTINGS_DESIGN_BUTTON_R_BORDER_COLOR: 'button-border-color',
  PP_SETTINGS_DESIGN_RIBBON: 'design-ribbon',
  PP_SETTINGS_DESIGN_RIBBON_TAB: 'design-ribbon-tab',
  PP_SETTINGS_DESIGN_RIBBON_RESET_BUTTON: 'reset-ribbon-styles',
  PP_SETTINGS_LAYOUT_TAB: 'layout-tab',
  PP_SETTINGS_LAYOUT_TAB_PAGE_TEXT_LAYOUT_SECTION_HEADER: 'page-text-layout-section-header',
  PP_SETTINGS_LAYOUT_TAB_TEXT_ALIGNMENT: 'text-alignment',
  PP_SETTINGS_LAYOUT_TAB_TITLE_ALIGNMENT: 'title-alignment',
  PP_SETTINGS_LAYOUT_TAB_SUBTITLE_ALIGNMENT: 'subtitle-alignment',
  PP_SETTINGS_LAYOUT_TAB_DISCLAIMER_ALIGNMENT: 'disclaimer-alignment',
  PP_SETTINGS_LAYOUT_TAB_DESCRIPTION_ALIGNMENT: 'description-alignment',
  PP_SETTINGS_LAYOUT_TAB_BENEFITS_SECTION_HEADER: 'benefitS-section-header',
  PP_SETTINGS_LAYOUT_TAB_BENEFITS_BULLETS: 'benefits-bullets',
  PP_SETTINGS_LAYOUT_TAB_BENEFITS_ARROW_BULLET: 'benefits-arrow-bullet',
  PP_SETTINGS_LAYOUT_TAB_BENEFITS_ALIGNMENT: 'benefits-alignment',
  PP_SETTINGS_LAYOUT_TAB_LINE_SPACING: 'benefits-layout-line-spacing',
  PP_SETTINGS_LAYOUT_TAB_TEXT_ALIGNMENT_LEFT: 'left-alignment',
  PP_SETTINGS_LAYOUT_TAB_TEXT_ALIGNMENT_CENTER: 'center-alignment',
  PP_SETTINGS_LAYOUT_TAB_TEXT_ALIGNMENT_RIGHT: 'right-alignment',
  PP_SETTINGS_TEXT_TAB_PAGE_HEADER: 'page-header-text',
  PP_SETTINGS_TEXT_TAB_BUTTON: 'button-text',
  PP_SETTINGS_TEXT_TAB_RIBBON: 'ribbon-text',
  PP_SETTINGS_SUPPORT_TAB: 'support-tab',
  PP_SETTINGS_SUPPORT_ARTICLES: 'support-link-button',
  PP_SETTINGS_SUPPORT_CHATBOT: 'customer-care-link-button',
  PP_WIDGET_SUBTITLE: 'app-subtitle',
};

export const PLANS_QUERY_PAGE_SIZE = 50;
