import type { DetailedPrice, PublicPlan } from '@wix/ambassador-pricing-plans-read-api/http';
import type { WidgetProps } from 'yoshi-flow-editor-runtime';
import { V1OrdersOrder } from '@wix/ambassador-membership-api/http';
import { IUser } from '@wix/native-components-infra/dist/src/types/types';
import { PaymentCompleteResultPublic } from '@wix/cashier-payments-widget/dist/src/sdk/types/PaymentCompleteResult';
import { Benefit } from '@wix/ambassador-pricing-plan-benefits-server/types';

export enum CheckoutStage {
  CHECKOUT_LOADED = 'login-in-checkout_loaded',
  LOGIN_CLICKED = 'auth_method_login',
  SIGNUP_CLICKED = 'auth_method_signup',
  PAYMENT_SECTION_LOADED = 'payment_component_loaded',
  START_DATE_CHANGED = 'start_date_changed',
}

export enum TabState {
  REGULAR = 'regular',
  HIGHLIGHTED = 'highlighted',
}

export interface TextData {
  ['button-text']?: string;
  ['disclaimer-text']?: string;
  ['free-trial-button-text']?: string;
  ['page-title-text']?: string;
  ['ribbon-text']?: string;
}

export enum PopupEnum {
  checkoutPreview = 'CHECKOUT_PREVIEW',
}

export enum MessageCode {
  PURCHASE_LIMIT_ERROR = 1,
  UNKNOWN_ERROR = 2,
  CHECKOUT_DEMO = 3,
  START_DATE_CHANGED = 4,
  START_DATE_UPDATE_FAILED = 5,
  PLAN_NOT_FOUND = 6,
  CHECKOUT_PAYLOAD_DECODE_FAILED = 7,
  STATUS_PAYLOAD_DECODE_FAILED = 8,
  INTEGRATION_DATA_DECODE_FAILED = 9,
}

export interface VerticalStatusContent {
  titleText: string;
  contentText?: string;
  buttonText: string;
}

interface BiOptions {
  referralInfo: string;
  referralId?: string;
}

export interface NavigateToSectionProps {
  appDefinitionId: string;
  sectionId: string;
  queryParams?: Object;
  state?: string;
}

export interface IntegrationData {
  planIds?: string[];
  navigateToSectionProps?: NavigateToSectionProps;
  navigateToPageProps?: string;
  verticalStatusContent?: VerticalStatusContent;
  biOptions?: BiOptions;
  restrictedPageId?: string;
  minStartDate?: string;
  maxStartDate?: string;
}

export interface CheckoutData {
  integrationData: IntegrationData;
  planId: string;
  orderId?: string;
  memberId?: string;
}

export interface PurchaseData {
  checkoutData: CheckoutData;
  orderId: string;
  snapshotId: string;
  freeTrialDays?: number;
}

export interface StatusData {
  purchaseData: PurchaseData;
  successful: boolean;
  error?: string;
  startDate?: string;
  planName?: string;
  ownerDemo?: boolean;
}

export type UserData = Pick<IUser, 'id' | 'loggedIn' | 'role' | 'instance'>;

export type SubPage =
  | { name: 'list'; integrationData: IntegrationData }
  | { name: 'checkout'; checkoutData: CheckoutData }
  | { name: 'status'; statusData: StatusData }
  | { name: 'restricted'; pageId: string; integrationData: IntegrationData };

export interface CommonProps {
  subPage: SubPage;
  shouldUpgrade?: boolean;
  showUpgradeModal?: boolean;
  message?: MessageCode;
  tabState: TabState;
  instance?: string;
  appInstanceId: string;
  metaSiteId?: string;
  siteOwnerId?: string;
  visitorId?: string;
  hideToast(): void;
  showToast(message: MessageCode): void;
  closeUpgradeModal(): void;
  navigateToHomePage(): void;
  biUpgradeReferralClick(referralInfo: string): void;
}

export interface ListProps {
  plans: PublicPlan[];
  hasMorePlans: boolean;
  popup: PopupEnum | null;
  selectPlan(plan: PublicPlan): void;
  hidePopup(): void;
  continueToDemoCheckout(): void;
  loadMore(): void;
}

export interface CheckoutProps {
  user: UserData;
  userEmail?: string;
  selectedPlan: PublicPlan;
  order?: V1OrdersOrder;
  detailedPrice: DetailedPrice;
  benefits: Benefit[];
  loginOnCheckout(): void;
  signupOnCheckout(): void;
  logout(): void;
  navigateToStatus(
    result: PaymentCompleteResultPublic,
    plan: PublicPlan,
    order: V1OrdersOrder,
    integrationData: IntegrationData,
  ): void;
  trackInitiateCheckout(): void;
  trackSelectPayment(id: string): void;
  demoBuyNowClicked(): void;
  biCheckoutStage(state: CheckoutStage): void;
  biPlanPurchased(result: PaymentCompleteResultPublic, order: V1OrdersOrder): void;
}

export interface StatusProps {
  successful?: boolean;
  translatedError?: string;
  order?: V1OrdersOrder;
  startDate?: string;
  navigateBackToTPA(): void;
  navigateBackToCheckout(): void;
  biThankYouPageCtaButtonClick(): void;
}

export interface RestrictedProps {
  missingPlan: boolean;
  switchAccounts(): void;
  navigateBackToPlanList(): void;
}

export type AppProps = CommonProps & ListProps & CheckoutProps & StatusProps & RestrictedProps;

export type AppWidgetProps = WidgetProps<AppProps>;

export interface IEvents {
  tabState: TabState;
}

export enum BenefitBulletType {
  NONE = 0,
  CIRCLE_TICK = 1,
  HOLLOW_CIRLCE_TICK = 2,
  TICK = 3,
  ARROW = 4,
}
