import { PublicPlan, PeriodUnit } from '@wix/ambassador-pricing-plans-read-api/http';

export const HIGHGLIGHTED_MOCK_PLAN_ID = 'some-id-2';

export const plansFixture: PublicPlan[] = [
  {
    id: 'some-id-1',
    dateCreated: new Date(),
    dateUpdated: new Date(),
    details: {
      benefits: ['6 classes', '3 individual sessions', 'Online resources'],
      description: 'Explore the basics with this introductory course',
      name: 'Beginner',
    },
    memberCanCancel: false,
    paymentOptions: {
      freeTrialDays: 0,
      price: {
        amount: '15',
        currency: 'USD',
      },
      recurring: false,
      validFor: {
        forever: false,
        period: {
          amount: 3,
          unit: PeriodUnit.MONTH,
        },
      },
    },
  },
  {
    id: HIGHGLIGHTED_MOCK_PLAN_ID,
    dateCreated: new Date(),
    dateUpdated: new Date(),
    details: {
      benefits: [
        '6 classes',
        '3 individual sessions',
        'Online resources',
        '1 guest pass',
        'Free WiFi',
        'Weekly newsletter',
      ],
      description: 'Perfect for creatives, freelancers, and experienced',
      name: 'Pro',
    },
    memberCanCancel: false,
    paymentOptions: {
      freeTrialDays: 0,
      price: {
        amount: '10',
        currency: 'USD',
      },
      recurring: true,
      validFor: {
        forever: false,
        period: {
          amount: 12,
          unit: PeriodUnit.MONTH,
        },
      },
    },
  },
  {
    id: 'some-id-3',
    dateCreated: new Date(),
    dateUpdated: new Date(),
    details: {
      benefits: [
        '6 classes',
        '3 individual sessions',
        'Online resources',
        '1 guest pass',
        'Free WiFi',
        'Weekly newsletter',
        'Priority support',
      ],
      description: 'Unlimited access to professional content',
      name: 'VIP',
    },
    memberCanCancel: false,
    paymentOptions: {
      freeTrialDays: 0,
      price: {
        amount: '30',
        currency: 'USD',
      },
      recurring: true,
      validFor: {
        forever: true,
        period: {
          amount: 12,
          unit: PeriodUnit.MONTH,
        },
      },
    },
  },
];
