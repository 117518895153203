import { PublicPlan } from '@wix/ambassador-pricing-plans-read-api/types';
import differenceWith from 'lodash/differenceWith';
import { isTruthy } from './isTruthy';

export function isFree(plan: PublicPlan): boolean {
  return getPriceAmount(plan) === '0';
}

export function getPriceAmount(plan: PublicPlan): string | undefined {
  return plan.paymentOptions?.price?.amount;
}

export function getPrice(plan: PublicPlan): number {
  return parseFloat(getPriceAmount(plan) ?? '');
}

export function isStartDateCustomizable(plan: PublicPlan): boolean {
  return !isFree(plan) && !!plan.buyerCanPickFutureStartDate;
}

export function removePlansWithIds(plans: PublicPlan[], ids: String[]) {
  return differenceWith(plans, ids, (plan: PublicPlan, id: String) => plan.id === id);
}

interface HasId<T = string> {
  id?: T;
}

export function getId<T extends HasId>(x: T) {
  return x.id;
}

export function getIds<T extends HasId>(xs: T[]): string[] {
  return xs.map(getId).filter(isTruthy);
}
