export enum TextAlignment {
  LEFT = 0,
  CENTER = 1,
  RIGHT = 2,
}

export enum TextAlignmentName {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
}

export function textAlignmentName(value: TextAlignment): TextAlignmentName {
  return textAlignmentMap[value];
}

const textAlignmentMap = {
  [TextAlignment.LEFT]: TextAlignmentName.LEFT,
  [TextAlignment.CENTER]: TextAlignmentName.CENTER,
  [TextAlignment.RIGHT]: TextAlignmentName.RIGHT,
};
